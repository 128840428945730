<template>
  <div id="main">
    <SideMenu
        v-if="isDataLoaded"
        @loading="loading"
        @showError="showError"
        @showErrorModal="showErrorModal"
        @showSuccess="showSuccess"
        @showSuccessModal="showSuccessModal"
        :isCollapse="isCollapse"
        :loginUser="loginUser"
        :accountMenus="accountMenus"
    />
    <!-- PAGE -->
    <div class="page">
      <Header
          @switchCollapse="switchCollapse()"
          @loading="loading"
          @showError="showError"
          @showErrorModal="showErrorModal"
          @showSuccess="showSuccess"
          @showSuccessModal="showSuccessModal"
          @access="access"
          :isCollapse="isCollapse"
          :isWebSize="isWebSize"
          :loginUser="loginUser"
      />

      <router-view
              v-if="isDataLoaded"
          @loading="loading"
          @showError="showError"
          @showErrorModal="showErrorModal"
          @showSuccess="showSuccess"
          @showSuccessModal="showSuccessModal"
          :loginUser="loginUser"
          :isWebSize="isWebSize"
          :accountMenus="accountMenus"
      />
    </div>

  </div>
</template>

<script>
// modules
import { ref } from 'vue'
import { h } from 'vue'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
// components
import Header from '@/components/Header.vue'
import SideMenu from '@/components/SideMenu.vue'
import CONSTANTS from "@/resources/constants.json";

export default {
  name: 'AdminIndex',

  components: {
    SideMenu,
    Header
  },

  setup() {
    const loader = ElLoading.service({
      lock: true
    });

    const activeIndex = ref('0');

    const windowWidth = ref(window.innerWidth);

    let bool = true;
    if (windowWidth.value >= 992) {
      bool = false;
    }

    const isCollapse = ref(bool);

    const isWebSize = ref(!bool);

    return {
      loader,
      activeIndex,
      windowWidth,
      isCollapse,
      isWebSize
    };
  },

  data() {
    return {
      loginUser: {
        id: null,
        loginUserAccountId: null,
        accountId: null,
        accountName: null,
        name: null,
        authority: null,
        accounts: null,
        account: null
      },
      accountMenus: [],
      isDataLoaded: false,
    }
  },

  methods: {
    access: async function () {
      const api_token = window.getCookie(`${CONSTANTS.api_token_name}`);

      const url = new URL(
          `${process.env.VUE_APP_API_DOMAIN}/api/v1/auth/access`
      );

      let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${api_token}`
      };

      await fetch(url, {
        method: "GET",
        headers: headers,
        credentials: 'include'
      })

          .then(response => {
            if (!response.ok) {
              if (response.status == 401 || response.status == 403) {
                // login エラー
                this.showErrorModal('認証失敗', ['認証に失敗しました。', 'もう一度ログインし直してください。'], null, 'logout');
              }
              this.loading(false);
              return false;
            }
            return response.json();
          })

          .then(json => {
            if (json === false) {
              this.loading(false);
              return false;
            }
            // 全体用の情報を取得
            this.loginUser.id = json['data']['user_id'];
            this.loginUser.loginUserAccountId = json['data']['user'].login_user_account_id;
            this.loginUser.name = json['data']['user'].name;
            this.loginUser.accountId = json['data']['account_id'];
            this.loginUser.accountName = json['data']['account'].name;
            this.loginUser.authority = json['data']['authority'].type;
            this.loginUser.accounts = json['data']['accounts'];
            this.loginUser.account = json['data']['account'];
            // 権限チェック
            // if (json['data']['type'] == 'admin') {
            //     this.is_admin = true;
            // } else if (json['data']['type'] == 'employee') {
            //     this.is_employee = true;
            // } else if (json['data']['type'] == 'guest') {
            //     this.is_guest =true;
            // }
            this.$emit('getLoginUser', this.loginUser);
          })

          .catch((e) => {
            console.log(e);
          })

          .finally(() => {
            this.loading(false);
          });
    },

    sidemenu: async function () {
      const api_token = window.getCookie(`${CONSTANTS.api_token_name}`);

      const url = new URL(
          `${process.env.VUE_APP_API_DOMAIN}/api/v1/account/usage/sidemenu`
      );

      let headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${api_token}`
      };

      await fetch(url, {
        method: "GET",
        headers: headers,
        credentials: 'include'
      })

          .then(response => {
            if (!response.ok) {
              if (response.status == 401 || response.status == 403) {
                // login エラー
                this.showErrorModal('認証失敗', ['認証に失敗しました。', 'もう一度ログインし直してください。'], null, 'logout');
              }
              this.loading(false);
              return false;
            }
            return response.json();
          })

          .then(json => {
            if (json) {
              this.accountMenus = json['sidemenu']['menus'];
              this.isDataLoaded = true;
            }
          })

          .catch((e) => {
            console.log(e);
          })

          .finally(() => {
            this.loading(false);
          });
    },

    switchCollapse: function () {
      if (this.isCollapse == true) {
        this.isCollapse = false;
      } else {
        this.isCollapse = true;
      }
    },

    calculateWindowWidth: function () {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 992) {
        this.isCollapse = false;
      } else {
        this.isWebSize = false;
        this.isCollapse = true;
      }

      if (this.windowWidth > 425) {
        this.isWebSize = true;
      } else {
        this.isWebSize = false;
      }
    },

    loading: function (bool) {
      if (bool == true) {
        this.loader = ElLoading.service({
          lock: true
        });
      } else {
        this.loader.close();
      }
    },

    showError: function (errors) {
      let message = errors.join('<br>');

      ElMessage({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: '<p>'+message+'</p>',
        type: 'error',
      })
    },

    showErrorModal: function (errorTitle, errorText, errorLink, nextAction) {
      let messages = [];
      if (errorText != null) {
        errorText.forEach(text => {
          let message = h('span', null, text);
          messages.push(message);
          messages.push(h('br', null, null));
        });
      }

      ElMessageBox({
        title: errorTitle,
        message: h('p', null, messages),
        showCancelButton: false,
        confirmButtonText: nextAction == "googleAuth" ? "Googleアカウント認証画面を表示する" : 'OK',
        cancelButtonText: '',
        type: nextAction == "googleAuth" ? 'warning' : 'error',
        center: true,
        customClass: nextAction == "googleAuth" ? "google-auth" : "",
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            switch (nextAction) {
              case 'close':
                break;
              case 'logout':
                this.$logout(this.loginUser.id);
                break;
              case 'reload':
                location.reload();
                break;
              case 'googleAuth':
                window.open(errorLink, '_blank');
                break;
              default:
                break;
            }
          } else {
            switch (nextAction) {
              case 'close':
                break;
              case 'logout':
                this.$logout(this.loginUser.id);
                break;
              case 'reload':
                location.reload();
                break;
              default:
                break;
            }
          }
          done()
        },
      })
    },

    showSuccess: function (messages) {
      let message = messages.join('<br>');

      ElMessage({
        showClose: true,
        dangerouslyUseHTMLString: true,
        message: '<p>'+message+'</p>',
        type: 'success',
      })
    },

    showSuccessModal: function (title, text, action) {
      this.$refs.SuccessModal.show(title, text, action);
    },
  },

  async mounted() {
    await this.access();
    await this.sidemenu();
    window.addEventListener('resize', this.calculateWindowWidth);
  }
}
</script>
