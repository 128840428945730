import { createRouter, createWebHistory } from 'vue-router'
import AdminIndex from '../AdminIndex.vue'
import UnLogin from '../UnLogin.vue'
import Youtube from '../Youtube.vue'
import Eq from '../Eq.vue'
import X from "@/X.vue";
import ApprovalGroups from "@/ApprovalGroups.vue";
import Instagram from '../Instagram.vue'
import Tiktok from '@/Tiktok.vue'

const routes = [
	{
		// ルート
		path: '',
		name: 'Root',
		component: () => import('../views/Root.vue')
	},
	{
		path: '',
		component: UnLogin,
		children: [
			/**
			 * 認証
			 */
			{
				// ログイン画面
				path: '/login',
				name: 'Login',
				meta: {
					title: 'ユーザーログイン',
				},
				component: () => import('../views/Auth/Login.vue')
			},
			{
				// パスワード忘れ画面
				path: '/password/forgot',
				name: 'ForgotPassword',
				meta: {
					title: 'パスワード再設定',
				},
				component: () => import('../views/Auth/ForgotPassword.vue')
			},
			{
				// 仮パスワード確認画面
				path: '/password/check/:hash',
				name: 'CheckPassword',
				meta: {
					title: 'パスワード検証',
				},
				component: () => import('../views/Auth/CheckPassword.vue')
			},
			{
				// パスワード再設定画面
				path: '/password/update/:hash',
				name: 'UpdatePassword',
				meta: {
					title: 'パスワード再設定',
				},
				component: () => import('../views/Auth/UpdatePassword.vue')
			},
			{
				// アカウント選択画面
				path: '/select_account',
				name: 'SelectAccount',
				meta: {
					title: 'アカウント選択',
				},
				props: true,
				component: () => import('../views/Auth/SelectAccount.vue')
			},
			/**
			 * 承認グループ
			 */
			{
				path: 'approval',
				component: ApprovalGroups,
				children: [
					{
						// 承認Xページ
						path: 'x/show',
						name: 'ApprovalXShow',
						meta: {
							title: 'Approval X Show',
						},
						component: () => import('../views/Approval/X/Show.vue')
					},
					{
						// 承認youtubeページ
						path: 'youtube/show',
						name: 'ApprovalYoutubeShow',
						meta: {
							title: 'Approval Youtube Show',
						},
						component: () => import('../views/Approval/Youtube/Show.vue')
					},
					{
						// 承認EQページ
						path: 'EQ/show',
						name: 'ApprovalEqShow',
						meta: {
							title: 'Approval EQ Show',
						},
						component: () => import('../views/Approval/Eq/Show.vue')
					},
					{
						// TikTok承認ページ
						path: 'tiktok/show',
						name: 'ApprovalTiktokShow',
						meta: {
							title: 'Approval for TikTok Show',
						},
						component: () => import('@/views/Approval/Tiktok/Show.vue')
					},
					{
						// Instagram承認ページ
						path: 'instagram/show',
						name: 'ApprovalInstagramShow',
						meta: {
							title: 'Approval for Instagram Show',
						},
						component: () => import('@/views/Approval/Instagram/Show.vue')
					},
					{
						// Instagramストーリー承認ページ
						path: 'instagramstory/show',
						name: 'ApprovalInstagramShowStory',
						meta: {
							title: 'Approval for Instagram ShowStory',
						},
						component: () => import('@/views/Approval/Instagram/ShowStory.vue')
					},
				]
			},
		],
	},
	{
		path: '/admin',
		component: AdminIndex,
		children: [
			/**
			 * TOP
			 */
			{
				// TOP
				path: '',
				name: 'Top',
				meta: {
					title: 'Top',
				},
				component: () => import('../views/Top.vue')
			},

			{
				/**
				 * X
				 */
				path: 'x',
				component: X,
				children: [
					{
						// アカウント編集
						path: 'account',
						name: 'XAccount',
						meta: {
							title: 'X Account',
						},
						component: () => import('../views/X/Account/Index.vue')
					},
					{
						// アカウント作成
						path: 'account/create',
						name: 'CreateXAccount',
						meta: {
							title: 'Create X Account',
						},
						component: () => import('../views/X/Account/Create.vue')
					},
					{
						// アカウント編集
						path: 'account/edit/:id',
						name: 'EditXAccount',
						meta: {title: 'Edit X Account',
						},
						component: () => import('../views/X/Account/Edit.vue')
					},
					{
						// 投稿一覧
						path: 'post/:id',
						name: 'XPost',
						meta: {
							title: 'X Post',
						},
						component: () => import('../views/X/Post/Index.vue')
					},
					{
						// 投稿登録
						path: 'post/create/:id',
						name: 'CreateXPost',
						meta: {
							title: 'Create X Post',
						},
						component: () => import('../views/X/Post/Create.vue')
					},
					{
						// 投稿詳細
						path: 'post/show/:id',
						name: 'ShowXPost',
						meta: {
							title: 'X Post Show',
						},
						component: () => import('../views/X/Post/Show.vue')
					},
					{
						// 投稿編集
						path: 'post/edit/:id',
						name: 'EditXPost',
						meta: {
							title: 'X Post Edit',
						},
						component: () => import('../views/X/Post/Edit.vue')
					},
					]
			},

			/**
			 * 承認グループ
			 */
			{
				path: 'approval_groups',
				component: ApprovalGroups,
				children: [
					{
						// グループ一覧
						path: '',
						name: 'ApprovalGroups',
						meta: {
							title: 'Approval Groups',
						},
						component: () => import('../views/ApprovalGroups/Index.vue')
					},
					{
						// グループ作成
						path: 'create',
						name: 'CreateApprovalGroups',
						meta: {
							title: 'Create Approval Groups',
						},
						component: () => import('../views/ApprovalGroups/Create.vue')
					},
					{
						// グループ詳細
						path: 'show/:id',
						name: 'ShowApprovalGroups',
						meta: {
							title: 'Show Approval Groups',
						},
						component: () => import('../views/ApprovalGroups/Show.vue')
					},
					{
						// グループ編集
						path: 'edit/:id',
						name: 'EditApprovalGroups',
						meta: {
							title: 'Edit Approval Groups',
						},
						component: () => import('../views/ApprovalGroups/Edit.vue')
					},
				]
			},

			/**
			 * アカウント管理
			 */
			{
				// アカウント一覧
				path: 'accounts',
				name: 'Accounts',
				meta: {
					title: 'Accounts',
				},
				component: () => import('../views/Accounts/Index.vue')
			},
			{
				// アカウント登録
				path: 'account/create',
				name: 'CreateAccount',
				meta: {
					title: 'CreateAccount',
				},
				component: () => import('../views/Accounts/Create.vue')
			},
			{
				// アカウント詳細
				path: 'accounts/:id',
				name: 'ShowAccount',
				meta: {
					title: 'ShowAccount',
				},
				component: () => import('../views/Accounts/Show.vue')
			},
			{
				// アカウント編集
				path: 'accounts/edit/:id',
				name: 'EditAccount',
				meta: {
					title: 'EditAccount',
				},
				component: () => import('../views/Accounts/Edit.vue')
			},
			{
				// アカウント設定
				path: '/accounts/setting',
				name: 'AccountSetting',
				meta: {
					title: 'AccountSetting',
				},
				component: () => import('../views/Accounts/Setting.vue')
			},

			/**
			 * ユーザー管理
			 */
			{
				// ユーザー一覧
				path: 'system_users',
				name: 'SystemUsers',
				meta: {
					title: 'SystemUsers',
				},
				component: () => import('../views/SystemUsers/Index.vue')
			},
			{
				// ユーザー詳細
				path: 'system_user/:id',
				name: 'ShowSystemUser',
				meta: {
					title: 'ShowSystemUser',
				},
				component: () => import('../views/SystemUsers/Show.vue')
			},
			{
				// ユーザーアカウント編集
				path: 'system_user/edit/:id',
				name: 'EditSystemUser',
				meta: {
					title: 'EditSystemUser',
				},
				component: () => import('../views/SystemUsers/Edit.vue')
			},

			/**
			 * ユーザーアカウント管理
			 */
			{
				// ユーザーアカウント一覧
				path: 'users',
				name: 'Users',
				meta: {
					title: 'Users',
				},
				component: () => import('../views/Users/Index.vue')
			},
			{
				// ユーザーアカウント登録
				path: 'user/create',
				name: 'CreateUser',
				meta: {
					title: 'CreateUser',
				},
				component: () => import('../views/Users/Create.vue')
			},
			{
				// ユーザーアカウント詳細
				path: 'user/:id',
				name: 'ShowUser',
				meta: {
					title: 'ShowUser',
				},
				component: () => import('../views/Users/Show.vue')
			},
			{
				// ユーザーアカウント編集
				path: 'user/edit/:id',
				name: 'EditUser',
				meta: {
					title: 'EditUser',
				},
				component: () => import('../views/Users/Edit.vue')
			},
			{
				// 設定画面
				path: 'setting',
				name: 'Setting',
				meta: {
					title: 'Setting',
				},
				component: () => import('../views/Users/Setting.vue')
			},
			{
				// パスワード変更画面
				path: 'update_password',
				name: 'ChangePassword',
				meta: {
					title: 'ChangePassword',
				},
				component: () => import('../views/Users/ChangePassword.vue')
			},

			/**
			 * グループ管理
			 */
			{
				// グループ一覧
				path: 'groups',
				name: 'Groups',
				meta: {
					title: 'Groups',
				},
				component: () => import('../views/Groups/Index.vue')
			},
			{
				// グループ詳細
				path: 'group/:id',
				name: 'ShowGroup',
				meta: {
					title: 'ShowGroup',
				},
				component: () => import('../views/Groups/Show.vue')
			},
			{
				// グループ登録
				path: 'group/create',
				name: 'CreateGroup',
				meta: {
					title: 'CreateGroup',
				},
				component: () => import('../views/Groups/Create.vue')
			},
			{
				// グループ編集
				path: 'group/edit/:id',
				name: 'EditGroup',
				meta: {
					title: 'EditGroup',
				},
				component: () => import('../views/Groups/Edit.vue')
			},

			/**
			 * 動画管理
			 */
			{
				// 動画一覧
				path: 'movies',
				name: 'Movies',
				meta: {
					title: 'Movies',
				},
				component: () => import('../views/Movies/Index.vue')
			},
			{
				// 動画登録
				path: 'movie/create',
				name: 'CreateMovie',
				meta: {
					title: 'CreateMovie',
				},
				component: () => import('../views/Movies/Create.vue')
			},
			{
				// 動画詳細
				path: 'movie/:id',
				name: 'ShowMovie',
				meta: {
					title: 'ShowMovie',
				},
				component: () => import('../views/Movies/Show.vue')
			},
			{
				// 動画詳細モック
				path: 'movie/mock',
				name: 'ShowMovieMock',
				meta: {
					title: 'ShowMovie Mock',
				},
				component: () => import('../views/Movies/ShowMock.vue')
			},
			{
				// 動画編集
				path: 'movie/edit/:id',
				name: 'EditMovie',
				meta: {
					title: 'EditMovie',
				},
				component: () => import('../views/Movies/Edit.vue')
			},

			/**
			 * システムのおしらせ管理
			 */
			{
				// システムのおしらせ一覧
				path: 'system_notifications',
				name: 'SystemNotifications',
				meta: {
					title: 'SystemNotifications',
				},
				component: () => import('../views/SystemNotifications/Index.vue')
			},
			{
				// システムのおしらせ登録
				path: 'system_notifications/create',
				name: 'CreateSystemNotification',
				meta: {
					title: 'CreateSystemNotification',
				},
				component: () => import('../views/SystemNotifications/Create.vue')
			},
			{
				// システムのおしらせ編集
				path: 'system_notifications/edit/:id',
				name: 'EditSystemNotification',
				meta: {
					title: 'EditSystemNotification',
				},
				component: () => import('../views/SystemNotifications/Edit.vue')
			},
			{
				// システムのおしらせ詳細
				path: 'system_notifications/:id',
				name: 'ShowSystemNotification',
				meta: {
					title: 'ShowSystemNotification',
				},
				component: () => import('../views/SystemNotifications/Show.vue')
			},

			/**
			 * アカウントのおしらせ管理
			 */
			{
				// アカウントのおしらせ一覧
				path: 'account_notifications',
				name: 'AccountNotifications',
				meta: {
					title: 'AccountNotifications',
				},
				component: () => import('../views/AccountNotifications/Index.vue')
			},
			{
				// アカウントのおしらせ登録
				path: 'account_notifications/create',
				name: 'CreateAccountNotification',
				meta: {
					title: 'CreateAccountNotification',
				},
				component: () => import('../views/AccountNotifications/Create.vue')
			},
			{
				// アカウントのおしらせ編集
				path: 'account_notifications/edit/:id',
				name: 'EditAccountNotification',
				meta: {
					title: 'EditAccountNotification',
				},
				component: () => import('../views/AccountNotifications/Edit.vue')
			},
			{
				// アカウントのおしらせ詳細
				path: 'account_notifications/:id',
				name: 'ShowAccountNotification',
				meta: {
					title: 'ShowAccountNotification',
				},
				component: () => import('../views/AccountNotifications/Show.vue')
			},

			{
				/**
				 * YouTube
				 */
				path: 'youtube',
				component: Youtube,
				children: [
					{
						// チャンネル一覧（選択画面）
						path: 'channel',
						name: 'YoutubeChannel',
						meta: {
							title: 'Youtube Channel',
						},
						component: () => import('../views/Youtube/Account/Index.vue')
					},
					{
						// チャンネル詳細
						path: 'channel/show/:id',
						name: 'ShowYoutubeChannel',
						meta: {
							title: 'Show Youtube Channel',
						},
						component: () => import('../views/Youtube/Account/Show.vue')
					},
					{
						// チャンネル登録
						path: 'channel/create',
						name: 'CreateYoutubeChannel',
						meta: {
							title: 'Create Youtube Channel',
						},
						component: () => import('../views/Youtube/Account/Create.vue')
					},
					{
						// 動画一覧
						path: 'video',
						name: 'YoutubeVideo',
						meta: {
							title: 'Youtube Video',
						},
						component: () => import('../views/Youtube/video/Index.vue')
					},
					{
						// 動画取り込み
						path: 'video/load',
						name: 'LoadYoutubeVideo',
						meta: {
							title: 'Load Youtube Video',
						},
						component: () => import('../views/Youtube/video/Load.vue')
					},
					{
						// 動画詳細
						path: 'video/show/:id',
						name: 'ShowYoutubeVideo',
						meta: {
							title: 'Show Youtube Video',
						},
						component: () => import('../views/Youtube/video/Show.vue')
					},
					{
						// 新規動画投稿
						path: 'video/create',
						name: 'CreateYoutubeVideo',
						meta: {
							title: 'Create Youtube Video',
						},
						component: () => import('../views/Youtube/video/Create.vue')
					},
					{
						// 動画編集
						path: 'video/edit/:id',
						name: 'EditYoutubeVideo',
						meta: {
							title: 'Edit Youtube Video',
						},
						component: () => import('../views/Youtube/video/Edit.vue')
					},
					{
						// 再生リスト
						path: 'playlist',
						name: 'YoutubePlaylist',
						meta: {
							title: 'Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Index.vue')
					},
					{
						// 再生リスト取り込み
						path: 'playlist/load',
						name: 'LoadYoutubePlaylist',
						meta: {
							title: 'Load Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Load.vue')
					},
					{
						// 再生リスト詳細
						path: 'playlist/show/:id',
						name: 'ShowYoutubePlaylist',
						meta: {
							title: 'Show Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Show.vue')
					},
					{
						// 再生リスト追加
						path: 'playlist/create',
						name: 'PostYoutubePlaylist',
						meta: {
							title: 'Create Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Create.vue')
					},
					{
						// 再生リスト編集
						path: 'playlist/edit/:id',
						name: 'EditYoutubePlaylist',
						meta: {
							title: 'Edit Youtube Playlist',
						},
						component: () => import('../views/Youtube/playlist/Edit.vue')
					},
				]
			},
			{
				/**
				 * Eq
				 */
				path: 'eq',
				component: Eq,
				children: [
					{
						// アカウント管理
						path: 'account/:id',
						name: 'EditEqAccount',
						meta: {
							title: 'Eq Account',
						},
						component: () => import('../views/Eq/Account/Edit.vue')
					},
					{
						// 新規配信登録
						path: 'delivery/create',
						name: 'CreateEqDelivery',
						meta: {
							title: 'Create Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Create.vue')
					},
					{
						// 配信情報編集
						path: 'delivery/edit/:id',
						name: 'EditEqDelivery',
						meta: {
							title: 'Edit Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Edit.vue')
					},
					{
						// 配信情報表示
						path: 'delivery/show/:id',
						name: 'ShowEqDelivery',
						meta: {
							title: 'Show Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Show.vue')
					},
					{
						// 配信一覧表示
						path: 'delivery',
						name: 'Equipmedia',
						meta: {
							title: 'Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Index.vue')
					},
					{
						// 配信取り込み表示
						path: 'delivery/load',
						name: 'LoadEqDelivery',
						meta: {
							title: 'Load Eq Delivery',
						},
						component: () => import('../views/Eq/Delivery/Load.vue')
					},
				]
			},
			{
				/**
				 * Instagram
				 */
				path: 'instagram',
				component: Instagram,
				children: [
					{
						// Instagramアカウント一覧
						path: 'accounts',
						name: 'InstagramAccount',
						meta: {
							title: 'Instagram Account',
						},
						component: () => import('../views/Instagram/Account/Index.vue')
					},
					{
						// Instagramアカウント登録
						path: 'accounts/create',
						name: 'CreateInstagramAccount',
						meta: {
							title: 'Create Instagram Account',
						},
						component: () => import('../views/Instagram/Account/Create.vue')
					},
					{
						// Instagramアカウント詳細
						path: 'accounts/show/:id',
						name: 'ShowInstagramAccount',
						meta: {
							title: 'Show Instagram Account',
						},
						component: () => import('../views/Instagram/Account/Show.vue')
					},
					{
						// Instagram投稿一覧
						path: ':instagram_id/posts',
						name: 'InstagramPost',
						meta: {
							title: 'Instagram Post',
						},
						component: () => import('../views/Instagram/Post/Index.vue'),
						props: true,
					},
					{
						// Instagram投稿詳細
						path: ':instagram_id/post/:post_id',
						name: 'ShowInstagramPost',
						meta: {
							title: 'Show Instagram Post',
						},
						component: () => import('../views/Instagram/Post/Show.vue'),
						props: true,
					},
					{
						// Instagram新規投稿
						path: ':instagram_id/posts/create',
						name: 'CreateInstagramPost',
						meta: {
							title: 'Create Instagram Post',
						},
						component: () => import('../views/Instagram/Post/Create.vue')
					},
					{
						// Instagram投稿編集
						path: ':instagram_id/posts/edit/:post_id',
						name: 'EditInstagramPost',
						meta: {
							title: 'Edit Instagram Post',
						},
						component: () => import('../views/Instagram/Post/Edit.vue')
					},
					{
						// InstagramStories投稿詳細
						path: ':instagram_id/poststory/:post_id',
						name: 'ShowInstagramStory',
						meta: {
							title: 'Show Instagram Story',
						},
						component: () => import('../views/Instagram/Story/ShowStory.vue'),
						props: true,
					},
					{
						// InstagramStories新規投稿
						path: ':instagram_id/posts/createstory',
						name: 'CreateInstagramStory',
						meta: {
							title: 'Create Instagram Story',
						},
						component: () => import('../views/Instagram/Story/CreateStory.vue')
					},
					{
						// InstagramStories投稿編集
						path: ':instagram_id/posts/editstory/:post_id',
						name: 'EditInstagramStory',
						meta: {
							title: 'Edit Instagram Story',
						},
						component: () => import('../views/Instagram/Story/EditStory.vue')
					},
				]
			},
			{
				/**
				 * Tiktok
				 */
				path: 'tiktok',
				component: Tiktok,
				children: [
					{
						path: 'account',
						name: 'TiktokAccountList',
						meta: {
							title: 'TikTok Account List'
						},
						component: () => import('@/views/Tiktok/Account/Index.vue')
					},
					{
						// アカウント登録
						path: 'account/create',
						name: 'CreateTiktokAccount',
						meta: {
							title: 'Create TikTok Account',
						},
						component: () => import('@/views/Tiktok/Account/Create.vue')
					},
					{
						// アカウント編集
						path: 'account/edit/:accountId?',
						name: 'EditTiktokAccount',
						meta: {
							title: 'Edit TikTok Account',
						},
						component: () => import('@/views/Tiktok/Account/Edit.vue')
					},
					{
						// ドメイン検証ファイルアップロード
						path: 'account/:accountId/domain_verify',
						name: 'TiktokDomainVerify',
						meta: {
							title: 'TikTok Account Domain Verify',
						},
						component: () => import('@/views/Tiktok/Account/DomainVerify.vue')
					},
					{
						// 投稿登録
						path: 'account/:accountId/post/create',
						name: 'CreateTiktokPost',
						meta: {
							title: 'Create TikTok Post',
						},
						component: () => import('@/views/Tiktok/Post/Create.vue')
					},
					{
						// 投稿一覧
						path: 'account/:accountId/post',
						name: 'TiktokPostList',
						meta: {
							title: 'TikTok Post List'
						},
						component: () => import('@/views/Tiktok/Post/Index.vue')
					},
					{
						// 投稿詳細
						path: 'account/:accountId/post/:postId',
						name: 'ShowTiktokPost',
						meta: {
							title: 'Show TikTok Post',
						},
						component: () => import('@/views/Tiktok/Post/Show.vue')
					},
					{
						// 投稿編集
						path: 'account/:accountId/post/:postId/edit',
						name: 'EditTiktokPost',
						meta: {
							title: 'Edit TikTok Post',
						},
						component: () => import('@/views/Tiktok/Post/Edit.vue')
					}
				]
			},
			{
				/**
				 * Analytics一覧
				 *
				 */
				path: 'analytics',
				name: 'Analytics',
				meta: {
					title: 'Analytics Access Tally',
				},
				component: () => import('../views/Analytics/Index.vue')
			},
			{
				/**
				 * FTP一覧
				 *
				 */
				path: 'ftp',
				name: 'Ftp',
				meta: {
					title: 'List Ftp Process',
				},
				component: () => import('../views/Ftp/Index.vue')
			},
			{
				/**
				 * FTP詳細
				 *
				 */
				path: 'ftp/show/:id',
				name: 'ShowFtp',
				meta: {
					title: 'Show Ftp Process',
				},
				component: () => import('../views/Ftp/Show.vue')
			},
			{
				/**
				 * EQCSVインポート一覧
				 *
				 */
				path: 'csvimport/eq',
				name: 'EqCsvImport',
				meta: {
					title: 'List EqCsvImport Process',
				},
				component: () => import('../views/CsvImport/Eq/Index.vue')
			},
			{
				/**
				 * EQCSVインポート詳細
				 *
				 */
				path: 'csvimport/eq/show/:id',
				name: 'ShowEqCsvImport',
				meta: {
					title: 'Show EqCsvImport Process',
				},
				component: () => import('../views/CsvImport/Eq/Show.vue')
			},
			{
				/**
				 * YoutubeCSVインポート一覧
				 *
				 */
				path: 'csvimport/youtube',
				name: 'YoutubeCsvImport',
				meta: {
					title: 'List YoutubeCsvImport Process',
				},
				component: () => import('../views/CsvImport/Youtube/Index.vue')
			},
			{
				/**
				 * Youtubeインポート詳細
				 *
				 */
				path: 'csvimport/youtube/show/:id',
				name: 'ShowYoutubeCsvImport',
				meta: {
					title: 'Show YoutubeCsvImport Process',
				},
				component: () => import('../views/CsvImport/Youtube/Show.vue')
			},
			// {
			// 	/**
			// 	 * CsvImport
			// 	 */
			// 	path: 'csvimport',
			// 	component: CsvImport,
			// 	children: [
			// 		{
			// 			/**
			// 			 * EQ一覧
			// 			 *
			// 			 */
			// 			path: 'eq',
			// 			name: 'EqCsvImport',
			// 			meta: {
			// 				originName: 'List EqCsvImport Process',
			// 			},
			// 			component: () => import('../views/CsvImport/Eq/Index.vue')
			// 		},
			// 		{
			// 			/**
			// 			 * EQ詳細
			// 			 *
			// 			 */
			// 			path: 'eq/show/:id',
			// 			name: 'ShowEqCsvImport',
			// 			meta: {
			// 				originName: 'Show ShowEqCsvImport Process',
			// 			},
			// 			component: () => import('../views/CsvImport/Eq/Show.vue')
			// 		},
			// 	],
			// },

			/**
			 * ログ管理
			 */
			{
				// ログ一覧
				path: 'logs',
				name: 'ProcessLogs',
				meta: {
					title: 'ProcessLogs',
				},
				component: () => import('../views/ProcessLogs/Index.vue')
			},

			{
				// メディアサンプル
				path: 'test-media',
				name: 'TestMedia',
				meta: {
					title: 'TestMedia',
				},
				component: () => import('../views/TestMedia.vue')
			},
		]
	},
	{
		path: "/:catchAll(.*)",
		name: 'NotFound',
		meta: {
			title: 'Not Found',
		},
		component: () => import('../views/NotFound.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
